<template>
  <div class=" py-5">
    <slick ref="slick" :options="slickOptions">
      <div
        class=""
        style="
    padding-right: 5%;  "
      >
        <b-card
          class="my-2 "
          cols="12"
          md="4"
          style=" width:100%; height: 384px; border-radius: 0;"
        >
          <div class="col-2 my-2">
            <img
              class="style-img-sv-card"
              src="@/assets/image-service/Icon_Jewelry.png"
              alt="submit"
            />
          </div>
          <p class="my-4 text-title-card ">
            {{ $t("message.content47") }}
          </p>
          <span class="text-title-sub-card ">{{
            $t("message.content48")
          }}</span>
          <br />
          <div class="text-card">
            <span>
              {{ $t("message.content49") }}
            </span>
          </div>
          <div class="d-flex mt-2">
            <div>
              <img
                src="@/assets/icon/Icon_Page_true.png"
                style="height: 5px; width: 17px; "
              />
            </div>

            <div class="px-2">
              <img
                src="@/assets/icon/Icon_Page_false.png"
                style="height: 5px;
width: 17px;"
              />
            </div>
          </div>
        </b-card>
      </div>
      <div
        class=""
        style="padding-left: 5%;
    padding-right: 5%;"
      >
        <b-card
          class="my-2 "
          cols="12"
          style="width:100%; height: 384px; border-radius: 0;"
        >
          <div class="col-2 my-2">
            <img
              class="style-img-sv-card"
              src="@/assets/image-service/Icon_Cost.png"
              alt="submit"
            />
          </div>

          <p class="my-4 text-title-card ">
            {{ $t("message.content50") }}
          </p>
          <span class="text-title-sub-card "></span>
          <br />
          <div class="text-card">
            <span>
              {{ $t("message.content51") }}
            </span>
          </div>
        </b-card>
      </div>
      <div
        class=""
        style="padding-left: 5%;
    "
      >
        <b-card
          class="my-2"
          cols="12"
          style="width:100%; height: 384px; border-radius: 0;"
        >
          <div class="col-2 my-2">
            <img
              class="style-img-sv-card"
              src="@/assets/image-service/Icon_Payment.png"
              alt="submit"
            />
          </div>

          <p class="my-4 text-title-card ">
            {{ $t("message.content52") }}
          </p>
          <span class="text-title-sub-card "></span>
          <br />
          <div class="text-card">
            <span>
              {{ $t("message.content53") }}
            </span>
          </div>
        </b-card>
      </div>
    </slick>
  </div>
</template>

<script>
import Slick from "vue-slick";
export default {
  components: { Slick },

  data() {
    return {
      slickOptions: {
        // centerMode: true,
        centerMode: true,
        centerPadding: "9%",
        autoplay: true,
        arrows: false,
        appendDots: false,
        autoplaySpeed: 6000,

        infinite: false,
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              centerMode: false,
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 800,
            settings: {
              centerMode: false,

              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              centerMode: false,

              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
    };
  },
  methods: {
    next() {
      this.$refs.slick.next();
    },

    prev() {
      this.$refs.slick.prev();
    },

    reInit() {
      this.$nextTick(() => {
        this.$refs.slick.reSlick();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/home";
@import "@/assets/scss/about";
@import "@/assets/scss/service";
@import "@/assets/scss/main";
</style>
