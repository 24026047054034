<template>
  <div class="container-fluid">
    <div class="auto-lr-ten">
      <div class="row">
        <div
          class="col-12 col-md-4 d-flex justify-content-start custom-postion align-items-center pd-none"
        >
          <div class="auto-l">
            <div class="small-text-sv">
              <span>{{ $t("message.content44") }}</span>
            </div>
            <div class="text-size-Bold mb-2">
              <p class="">{{ $t("message.content45") }}</p>
              <div class="custom-borderline"></div>
            </div>
            <div class="text-size-Regular">
              <p>
                {{ $t("message.content46") }}
              </p>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-8 pd-none-sv">
          <img class="img-bg-sv" src="@/assets/image-service/service0.png" />
          <img
            class="img-bg-sv-mobile"
            src="@/assets/image-service/screen_shot_service1.jpg"
          />
        </div>
      </div>
    </div>

    <div class="center-respone pt-5" style="">
      <b-row class="justify-content-md-center">
        <b-col cols="12" md="3" class="justify-content-md-center">
          <img class="img-respone" src="@/assets/image-service/service17.jpg" />
        </b-col>
        <b-col cols="12" md="3" class="justify-content-md-center">
          <img class="img-respone" src="@/assets/image-service/service15.jpg" />
        </b-col>
        <b-col cols="12" md="3" class="justify-content-md-center">
          <img class="img-respone" src="@/assets/image-service/service18.jpg" />
        </b-col>
        <b-col cols="12" md="3" class="justify-content-md-center">
          <img class="img-respone" src="@/assets/image-service/service19.jpg" />
        </b-col>
      </b-row>
    </div>

    <div class="lr-card-service">
      <casrsilder />
    </div>
    <div>
      <services />
    </div>
    <div class="row">
      <get-tounch />
    </div>
  </div>
</template>

<script>
import Gettounch from "@/components/Gettouch.vue";
import CasrSilder from "@/components/Card_Silder.vue";
import Services from "@/components/Services.vue";

export default {
  components: {
    "get-tounch": Gettounch,
    casrsilder: CasrSilder,
    services: Services,
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/home";
@import "@/assets/scss/about";
@import "@/assets/scss/main";
@import "@/assets/scss/service";
</style>
