<template>
  <div class="color-bg row ">
    <div class="pt-5 mt-4 custom-mb">
      <div class="container">
        <div class="text-hrad-sv ">
          <p>{{ $t("message.content54") }}</p>
        </div>
        <div class="text-title-sv  auto-rl-thirteen  ">
          <p>{{ $t("message.content55") }}</p>
        </div>
      </div>
      <div class="custom-sv-lr pt-3">
        <div class="row justify-content-md-center mx-4 my-4">
          <div class="col-12 col-md-7 pd-none">
            <img
              class="img-respone-sv"
              src="@/assets/image-service/service1.png"
            />
          </div>
          <div class="col-12 col-md-5 center-and-start pd-none py-3">
            <div class="text-title-sub-sv auto-rl-text ">
              <p>
                {{ $t("message.content56") }}
              </p>
            </div>
          </div>
        </div>

        <b-row class="justify-content-md-center mx-4 my-4">
          <b-col cols="12" md="2" class="pd-none">
            <img
              class="img-respone-sv"
              src="@/assets/image-service/service2.png"
            />
          </b-col>
          <b-col cols="12" md="3" class="center-and-start pd-none py-3">
            <div class="text-title-sub-sv auto-rl-text ">
              <p>{{ $t("message.content111") }}</p>
              <p>{{ $t("message.content112") }}</p>
              <p>{{ $t("message.content113") }}</p>
            </div>
          </b-col>
          <b-col cols="12" md="2" class="pd-none custom-d-none">
            <img
              class="img-respone-sv "
              src="@/assets/image-service/service3.png"
            />
          </b-col>
          <b-col cols="12" md="5" class="pd-none custom-d-none">
            <img
              class="img-respone-sv"
              src="@/assets/image-service/service4.png"
            />
          </b-col>
        </b-row>
        <!--  -->
        <b-row class=" justify-content-md-center mx-4 my-4">
          <b-col cols="12" md="2" class="pd-none ">
            <img
              class="img-respone-sv"
              src="@/assets/image-service/service5.png"
            />
          </b-col>

          <b-col cols="12" md="4" class="pd-none custom-d-none">
            <img
              class="img-respone-sv"
              src="@/assets/image-service/service6.png"
            />
          </b-col>
          <b-col cols="12" md="3" class="center-and-start pd-none py-3">
            <div class="text-title-sub-sv auto-rl-text ">
              <p class="">{{ $t("message.content58") }}</p>
            </div>
          </b-col>
          <b-col cols="12" md="3" class="pd-none custom-d-none">
            <img
              class="img-respone-sv"
              src="@/assets/image-service/service7.png"
            />
          </b-col>
        </b-row>
        <!--  -->
        <b-row class="justify-content-md-center mx-4 my-4">
          <b-col cols="12" md="4" class="pd-none ">
            <img
              class="img-respone-sv"
              src="@/assets/image-service/service8.png"
            />
          </b-col>

          <b-col cols="12" md="4" class="pd-none custom-d-none">
            <img
              class="img-respone-sv"
              src="@/assets/image-service/service9.png"
          /></b-col>
          <b-col cols="12" md="4" class="center-and-start pd-none py-3 ">
            <div class="text-title-sub-sv auto-rl-text">
              <p>{{ $t("message.content59") }}</p>
            </div>
          </b-col>
        </b-row>

        <b-row class="justify-content-md-center mx-4 my-4">
          <b-col cols="12" md="6" class="center-and-start pd-none py-3">
            <div class="text-title-sub-sv auto-rl-text">
              <p>{{ $t("message.content60") }}</p>
            </div>
          </b-col>

          <b-col cols="12" md="6" class="pd-none custop-swap-top">
            <img
              class="img-respone-sv"
              src="@/assets/image-service/service10.png"
          /></b-col>
        </b-row>

        <b-row class="justify-content-md-center mx-4 my-4">
          <b-col cols="12" md="3" class="pd-none ">
            <img
              class="img-respone-sv"
              src="@/assets/image-service/service11.png"
          /></b-col>

          <b-col cols="12" md="3" class="pd-none custom-d-none">
            <img
              class="img-respone-sv"
              src="@/assets/image-service/service12.png"
          /></b-col>
          <b-col cols="12" md="3" class="center-and-start pd-none py-3">
            <div class="text-title-sub-sv auto-rl-text">
              <p>{{ $t("message.content61") }}</p>
            </div>
          </b-col>
          <b-col cols="12" md="3" class="pd-none custom-d-none">
            <img
              class="img-respone-sv"
              src="@/assets/image-service/service13.png"
          /></b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/home";
@import "@/assets/scss/about";
@import "@/assets/scss/main";
@import "@/assets/scss/service";
</style>
